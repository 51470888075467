<template>
  <v-card>
    <v-card-title>
      <span class="text-h5">{{ titulo }}</span>
    </v-card-title>

    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="12" md="12" class="pb-0">
            <v-text-field
              v-model="dataTicket.titulo"
              label="Título"
              outlined
              required
              :disabled="disabledTicket"
            ></v-text-field>
            <!-- <v-text-field
                          v-model="dataTicket.titulo"
                          label="Título"
                          outlined
                          required
                          :rules="[v => !!v || 'Email is required']"
                        ></v-text-field> -->
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-select
              v-model="dataTicket.tipo"
              :items="tiposTicket"
              label="Área de apoyo"
              :disabled="disabledTicket"
              outlined
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-select
              v-model="dataTicket.lugar"
              :items="lugares"
              label="Lugar"
              outlined
              :disabled="disabledTicket"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="12"
            class="pb-0"
            v-if="dataTicket.lugar === 'Instalación'"
          >
            <v-combobox
              v-model="dataTicket.parametrosLugar"
              :items="parametrosLugar"
              label="Parámetros del lugar"
              multiple
              outlined
              chips
              class="combo-parametros"
              :disabled="disabledTicket"
            ></v-combobox>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-select
              v-model="dataTicket.tipoPlanificacion"
              :items="tiposPlanificacion"
              label="Tipo de Planificación"
              outlined
              :disabled="disabledTicket"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-select
              v-model="dataTicket.tipoCreador"
              :items="tiposCreador"
              label="Tipo de Creador"
              outlined
              :disabled="disabledTicket"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-select
              v-model="dataTicket.tipoServicio"
              :items="tiposServicios"
              label="Tipo de Servicio"
              outlined
              :disabled="disabledTicket"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" md="6" class="pb-0">
            <v-select
              v-model="dataTicket.asignado_a"
              :items="asignacion"
              label="Asignación"
              outlined
              :disabled="disabledTicket"
            ></v-select>
          </v-col>
          <v-col
            cols="12"
            sm="6"
            md="12"
            class="pb-0"
            v-if="dataTicket.tipoServicio === 'Programa de aseo'"
          >
            <v-select
              v-model="dataTicket.grupoTarea"
              :items="gruposTareas"
              label="Grupo de tareas"
              outlined
              :disabled="disabledTicket"
            ></v-select>
          </v-col>
          <!-- <v-col cols="12" sm="6" md="6">
                        <v-select
                          v-model="dataTicket.estado"
                          :items="estadoTicket"
                          label="Estado"
                          outlined
                        ></v-select>
                      </v-col> -->
          <!-- <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="dataTicket.creado_por"
              :value="user.user"
              disabled
              label="Creado por"
            ></v-text-field>
          </v-col> -->
        </v-row>
      </v-container>
    </v-card-text>

    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="blue darken-1" text @click="close">
        Volver
      </v-btn>
      <v-btn v-if="!disabledTicket" color="blue darken-1" text @click="save">
        Guardar
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import UserProfile from "../../data";
import router from "../../router";

export default {
  data: () => ({
    user: UserProfile.getUserData(),
    search: "",
    dialog: false,
    dialogDelete: false,
    lugares: ["Cliente", "Instalación"],
    tiposPlanificacion: ["Programado", "No programado"],
    tiposCreador: [
      "Cliente administrador",
      "Cliente usuario final",
      "FC de la locación",
      "FC fuera de la locación",
    ],
    tiposServicios: [
      "Aseo tradicional",
      "Aseo terminal",
      "Aseo recurrente",
      "Programa de aseo",
      "Vidrios a nivel",
      "Vidrios en altura",
      "Limpieza en altura",
    ],
    gruposTareas: ["Fin de semana", "Cada 15 días", "Cada 30 días"],
    parametrosLugar: ["Dirección", "Recinto", "Piso", "Zona/Área"],
    tiposTicket: [
      "Capacitación",
      "Tarea de RRHH",
      "Tarea administrativa",
      "Aviso cambio RRHH",
      "Mantenimiento de equipos",
    ],
    asignacion: ["Cuadrilla", "Persona", "Cargo"],
    estadoTicket: ["No leído", "Abierto", "Resuelto", "Cerrado"],
    headers: [
      {
        text: "Título",
        align: "start",
        sortable: false,
        value: "titulo",
      },
      { text: "Tipo de ticket", value: "tipo" },
      // { text: "Descripción", value: "descripcion" },
      { text: "Lugar", value: "lugar" },
      { text: "Planificación", value: "tipoPlanificacion" },
      { text: "Tipo de Creador", value: "tipoCreador" },
      { text: "Servicio", value: "tipoServicio" },
      // { text: "Grupo de tareas", value: "grupoTarea" },
      { text: "Asignación", value: "asignado_a" },
      // { text: "Estado", value: "estado" },
      { text: "Creado por", value: "creado_por" },
      { text: "Acciones", value: "actions", sortable: false },
    ],
  }),
  props: {
    titulo: {
      type: String,
      default: "Nuevo ticket",
    },
    dataTicket: {
      type: Object,
      default: {},
    },
    disabledTicket: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.dataTicket.creado_por = this.user.user;
  },
  computed: {},

  watch: {},

  created() {},

  methods: {
    close() {
      this.$emit("recibeTickect", undefined)
    },

    save() {
      this.$emit("recibeTickect", this.dataTicket)
    },
  },
};
</script>

<style lang="scss">
.combo-parametros {
  .v-select__selections {
    min-height: 56px !important;
  }
}
.theme--dark.v-chip.default {
  background: #f1f1f1;
  color: rgba(0, 0, 0, 0.87);
}
.v-text-field__details {
  display: none;
}
</style>
